<template>
  <div class="edu-frame">
    <edu-nav-bar title="学习天地" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>
    <div style="width: 96%;margin: 10px auto;background-color: white;height: calc(100vh - 46px - 20px);overflow: auto;">
      <div style="text-align: center;font-weight: bold;margin-top: 10px;">
        {{ learningWorld.learningTitle }}
      </div>
      <div class="learningWorld" v-html="learningWorld.learningContent"></div>
    </div>

  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import {Button, Icon, List, Tag} from "vant";
import ActivityApi from "@/api/ActivityApi";
import Tools from "@/api/Tools";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanTag: Tag,
    VanList: List,
    VanIcon: Icon
    /*VanCheckbox: Checkbox*/
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      learningWorld: {},
      cust: Tools.getCurCust(),
    }
  },
  methods: {
    getLearningWorldsById() {
      var learningId = this.$route.query.learningId
      ActivityApi.getLearningWorldsById(learningId).then(response => {
        var str = JSON.stringify(response.res)
        str = str.replaceAll('<img', '<img style=\\"width: 95%;margin-left: 2.5%;\\" ')
        this.learningWorld = JSON.parse(str)
      })
    },
    addLearningWorldViews() {
      var learningId = this.$route.query.learningId
      var custId = this.cust.custId
      ActivityApi.addLearningWorldViews({ learningId: learningId, custId: custId }).then(response => {
        if (response.code == 100) {
          console.log('response.code == ', response.code)
        }
      }).catch(() => {})
    }
  },
  mounted() {
    this.getLearningWorldsById()
    this.addLearningWorldViews()
  }
}
</script>
<style scoped>
.learningWorld {
  width: 95%;
  margin: 0 auto;
}
.learningWorld img{
  width: 90%;
  margin: 0 auto;
}
</style>
